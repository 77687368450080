<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card v-if="user">
      <v-card-text class="pa-0">
        <v-window v-model="window" touchless>
          <v-window-item :value="0">
            <v-card-title>
              Are you sure?
            </v-card-title>
            <v-card-text>
              <v-btn color="color3 color3Text--text" block @click.stop="dialog = false">
                I'll view it later
              </v-btn>
              <v-btn class="mt-3" color="color3" text block @click.stop="skip4Ever">
                don't show me this again
              </v-btn>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="1">
            <v-card-title>
              Welcome Coach {{user.lastName}}!
            </v-card-title>
            <v-card-text>
              <p>I want to take a quick second to welcome you to Volleyball Life and give you a few quick pointers.</p>
              You are getting a first glimpse of our recruiting and virtual showcase platform. It is still early in the development cycle and will continue to evolve over the next few weeks.
              Truthfully, I hope it will never stop evolving. After all, Volleyball Life is built by players, for players and we are passionate about the game.
            </v-card-text>
          </v-window-item>
          <v-window-item :value="2">
            <v-card-title>
              The Menu
            </v-card-title>
            <v-card-text>
              Most everything you need can be found in the menu. If you are on a mobile device or the menu isn't showing on the left of your screen,
              click the "hamburger" (the 3 bars) up on the top left circled in the picture below.
              <v-img
                eager
                src="https://vblimages.blob.core.windows.net/images/1382c99a-156c-42c6-94c7-ab6de2782a25.jpg"
              ></v-img>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="3">
            <v-card-title>
              All Players
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="5">
                  <v-img
                    src="https://vblimages.blob.core.windows.net/images/72beffaf-c087-4f4a-a745-5473fe287f78.PNG"
                    max-height="200"
                    contain
                    eager
                  ></v-img>
                </v-col>
                <v-col cols="12" sm="7">
                  <p>The players page is the first page in your menu and only available to you and other college coaches.</p>
                  <v-alert type="info" :value="true">
                    This page will take a few seconds to load. We will optimize it very soon.
                  </v-alert>
                  We currently have over 6000 players. Obviously we don't have extended profiles for all of them, but we want you to be able to see all the players that have results in the system.
                </v-col>
              </v-row>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="4">
            <v-card-title>
              Showcase Tournaments
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="7">
                  <p>Showcase events will also have a players page specific to the tournament</p>
                  This will show you all the players in the tournament and give you quick access to their full profile and tournament schedule.
                </v-col>
                <v-col cols="12" sm="5">
                  <v-img
                    src="https://vblimages.blob.core.windows.net/images/cd126a2a-f51e-484d-a798-d2d0eac0c458.PNG"
                    contain
                    eager
                  ></v-img>
                </v-col>
              </v-row>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="5">
            <v-card-title>
              Player Card Buttons
            </v-card-title>
            <v-card-text>
              <p>When you are surfing around the profiles, try out the following:</p>
              <v-img
                src="https://vblimages.blob.core.windows.net/images/93f4707f-3ea4-45c7-a1c2-8ffaf9907ef9.jpg"
                contain
                eager
              ></v-img>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="6">
            <v-card-title>
              Filtering
            </v-card-title>
            <v-card-text>
              <p>We have basic filtering capabilities now. Look for the funnel icon at the top of any players page.</p>
              <p>As I have stated before, we will continue to evolve and add more advanced filtering very soon.</p>
              <v-img
                src="https://vblimages.blob.core.windows.net/images/3929018d-3586-4d36-9135-57888afab98b.jpg"
                contain
                eager
              ></v-img>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="7">
            <v-card-title>
              Feedback always welcome!
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <p>Thank you for taking the time to step through this. I want you to know I am always available and love feedback and ideas.</p>
                  <p>Once again, I hope that we can continuosly evolve this platform to help you and our players.</p>
                  <p>If you have any comments, suggestions, ideas, or just want to chat about volleyball, don't hesitate to reach out to me directly.</p>
                  <div class="d-flex align-center">
                    <v-avatar
                      size="60"
                    >
                      <img src="https://vblimages.blob.core.windows.net/images/Profile2.png" alt="~:-)">
                    </v-avatar>
                    <div class="pl-3">
                      Jon Alvarez<br>
                      CEO VolleyballLife.com<br>
                      214-674-8568
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-fab-transition>
          <v-btn v-if="window === 1" color="color3" text small @click.stop="window=0">skip</v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            v-if="window > 1"
            color="color3 color3Text--text"
            fab small
            @click.stop="window--"
          >
            <v-icon>fas fa-caret-left</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            v-if="window > 1"
            color="color3 color3Text--text"
            text small
            @click.stop="window--"
          >
            prev
          </v-btn>
        </v-fab-transition>
        <v-spacer></v-spacer>
        <v-fab-transition>
          <v-btn
            color="color3 color3Text--text"
            text small
            @click.stop="next"
          >
            {{ window === 7 ?'close' : 'next'}}
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            color="color3 color3Text--text"
            fab small
            @click.stop="next"
          >
            <v-icon>fas fa-caret-right</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import * as mutations from '@/store/MutationTypes'

export default {
  data () {
    return {
      dialog: false,
      noShow: false,
      window: 1
    }
  },
  computed: {
    ...mapGetters(['user', 'noCollegeHowTo'])
  },
  methods: {
    checkOpen () {
      if (this.user && this.user.isCollegeCoach && !this.noCollegeHowTo) this.dialog = true
    },
    next () {
      if (this.window < 7) {
        this.window++
      } else {
        this.noShow = true
        this.dialog = false
      }
    },
    skip4Ever () {
      this.noShow = true
      this.dialog = false
    }
  },
  watch: {
    user: 'checkOpen',
    dialog: function (val) {
      if (!val) {
        this.noShow && this.$store.commit(mutations.SET_NO_HOWTO_COLLEGE)
      }
    }

  },
  mounted () {
    this.checkOpen()
  }
}
</script>
